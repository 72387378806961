
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Button } from '@loanpal/lumos__core';
import Link from 'next/link';
import Head from 'next/head';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`GoodLeap Developer API`}</h1>
    <p><img parentName="p" {...{
        "src": "https://img.shields.io/badge/Current%20Version-v2.0.0-brightgreen",
        "alt": "Current Version v2.0.0"
      }}></img></p>
    <p>{`The GoodLeap API is organized around `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Representational_state_transfer"
      }}>{`REST`}</a>{`.
Our API has predictable resource-oriented URLs and returns `}<a parentName="p" {...{
        "href": "https://json.org"
      }}>{`JSON-encoded`}</a>{`
responses, and uses standard HTTP response codes, authentication, and verbs.`}</p>
    <Link href="/reference" as="/reference" passHref mdxType="Link">
  <Button as="a" variant="primary" mdxType="Button">
    View Reference
  </Button>
    </Link>
    <h2>{`What's a REST API?`}</h2>
    <p>{`An `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Application_programming_interface"
      }}>{`API`}</a>{` is an application programming
interface - in short, it's a set of rules that lets programs talk to
each other, exposing data, and functionality across the internet in
a consistent format.`}</p>
    <p>{`REST stands for `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Representational_state_transfer"
      }}>{`Representational State Transfer`}</a>{`.
This is an architectural pattern that describes how distributed systems can expose a consistent interface.
When people use the term 'REST API', they are generally referring to an API accessed via HTTP protocol at a
predefined set of URLs.`}</p>
    <p>{`These URLs represent various resources - any information or content accessed at that location, which can be
returned as JSON, HTML, audio files, or images. Often, resources have one or more methods that can be performed on
them over HTTP, like GET, POST, PUT and DELETE.`}</p>
    <h2>{`Getting Started`}</h2>
    <p>{`If you are new to the GoodLeap API for the first time, head over to the `}<a parentName="p" {...{
        "href": "/docs/"
      }}>{`Development Guide`}</a>{` section. If you are migrating from v1 of the API to v2, check out `}<a parentName="p" {...{
        "href": "/docs/migration-station"
      }}>{`Migration Station`}</a>{`.`}</p>
    <h2>{`Getting Help`}</h2>
    <p>{`For help integrating with the latest version of the API, you can reach out to our `}<a parentName="p" {...{
        "href": "mailto:cfappsupport@goodleap.com?subject=API%20V2%20Support%20Request&body=Installer%20Partner%20Name:%20%3C%3CINSERT%20PARTNER%20NAME%20HERE%3E%3E%0D%0ARelationship%20to%20Installer%20Partner:%20%3C%3CChoose%20one%20of%20the%20following:%20Partner%20Employee,%20Integrator%20for%20the%20Installer%20Partner,%20Other:%20Describe%20Relationship%3E%3E%0D%0A%0D%0ASupport%20Request%20Needs:%20%3C%3CINSERT%20REQUEST%20HERE%3E%3E"
      }}>{`Customer Success Team`}</a>{` here with any questions you may have.`}</p>
    <p>{`If you have suggestions for additional content (use cases, examples, code samples) you would like to see on the developer portal, please suggest those `}<a parentName="p" {...{
        "href": "mailto:cfappsupport@goodleap.com?subject=Developer%20Portal%20Content%20Request&body=Installer%20Partner%20Name:%20%3C%3CINSERT%20PARTNER%20NAME%20HERE%3E%3E%0D%0ARelationship%20to%20Installer%20Partner:%20%3C%3CChoose%20one%20of%20the%20following:%20Partner%20Employee,%20Integrator%20for%20the%20Installer%20Partner,%20Other:%20Describe%20Relationship%3E%3E%0D%0A%0D%0AContent%20Request:%20%3C%3CINSERT%20REQUEST%20HERE%3E%3E"
      }}>{`here`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;